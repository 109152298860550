<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top" v-if="isXian">
      <div class="top-select">
        <el-form :model="SerachInput" label-width="85px">
          <el-form-item label="注册时间" style="width: 43.9%">
            <el-col>
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="用户昵称">
            <el-col>
              <el-input
                @keyup.enter.native="handleQuery"
                v-model="SerachInput.regionTwo"
                placeholder="请输入"
              >
              </el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="用户区域">
            <el-col :span="8" style="width: 46%">
              <el-select
                v-model="SerachInput.city"
                placeholder="请选择"
                @change="provinces(SerachInput.city)"
              >
                <el-option
                  v-for="item in optionssex"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col
              class="line"
              :span="1"
              style="margin-left: 5px; margin-right: 5px"
            >
              --</el-col
            >
            <el-col :span="8" style="width: 46%">
              <el-select v-model="SerachInput.province" placeholder="请选择">
                <el-option
                  v-for="item in optionssexs"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input
              v-model="SerachInput.phones"
              placeholder="请输入手机号码"
              style="width: 172px"
              @keyup.enter.native="handleQuery"
            ></el-input>
          </el-form-item>
          <el-form-item label="家庭ID">
            <el-input
              v-model="SerachInput.familyID"
              placeholder="请输入家庭ID"
              style="width: 172px"
              @keyup.enter.native="handleQuery"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="0px">
            <img
              src="../../assets/images/query.png"
              style="width: 49px"
              alt=""
              @click="handleQuery"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          ref="multipleTable"
          style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column prop="phone" label="手机号码" width="115">
          </el-table-column>
          <el-table-column prop="userName" label="用户昵称" width="120">
          </el-table-column>
          <el-table-column prop="sexCn" label="用户性别" width="100">
          </el-table-column>
          <el-table-column prop="cityCn" label="用户区域"> </el-table-column>
          <el-table-column prop="createDate" label="注册时间" width="180">
          </el-table-column>
          <el-table-column prop="loginDate" label="最后登录时间" width="180">
          </el-table-column>
          <el-table-column prop="famlilyCount" label="隶属家庭" width="100">
            <template slot-scope="scope">
              <el-button
                size="mini"
                v-if="scope.row.famlilyCount"
                @click="nowState(scope.$index, scope.row)"
                ><span>{{ scope.row.famlilyCount }}</span></el-button
              >
              <span v-else>{{ scope.row.famlilyCount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="photoUrl" label="头像" width="100">
            <template slot-scope="scope">
              <el-button
                size="small"
                style="cursor: pointer"
                @click="open(scope.row)"
              >
                <span>查看</span>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="realAuth" label="实名状态" width="100">
          </el-table-column>
          <el-table-column prop="useState" label="账号状态" width="100">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >更改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <p class="bottom-shu">共计{{ totalCount }}条数据</p>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
                @pagination="getEquipment"
              >
              </el-pagination>
              <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <el-button>数据分析</el-button>
            <el-button @click="exportList">数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加的弹框 -->
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          label-width="70px"
        >
          <el-form-item label="选择状态" style="margin-left: 3%">
            <el-select
              v-model="formInline.useState"
              @change="useStates(formInline.useState)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionStatus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="preservation">保 存</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        title="查看图片"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <span
          ><img style="width: 100%; margin: 0 auto" :src="photoUrls"
        /></span>
      </el-dialog>
    </div>
    <div>
      <el-dialog title="家庭列表" :visible.sync="dialogVisibleFam" width="40%">
        <el-form :model="configuren">
          <el-form-item
            v-for="(item, index) in familyArry"
            :key="index"
            :label-width="formLabelWidths"
            style="display: flex"
          >
            <div style="display: flex">
              <input
                v-model="item.id"
                size="small"
                style="
                  width: 40%;
                  height: 38px;
                  margin-left: 15px;
                  border: 1px solid #dcdfe6;
                  padding: 0 15px;
                "
              />
              <input
                v-model="item.name"
                size="small"
                style="
                  width: 100%;
                  height: 38px;
                  margin-left: 15px;
                  border: 1px solid #dcdfe6;
                  padding: 0 15px;
                  margin-right: 20px;
                "
              />
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="dialogVisibleFam = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  inject: ["reload"],
  data() {
    return {
      familyArry: [],
      configuren: {},
      formLabelWidths: "30px",
      clientHeight: document.body.clientHeight,
      dialogVisibleFam: false,
      //增加的弹框
      dialogVisible: false,
      centerDialogVisible: false,
      isXian: true,
      photoUrls: "",
      unitName: [],
      //性别下拉
      optionssex: [],
      optionssexs: [],
      // optionssexsType:[],
      optionStatus: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "条目编号",
        },
        {
          value: "3",
          label: "分类",
        },
      ],
      overallForm: {
        time: [],
      },
      SerachInput: {
        tiem: null,
        tiems: null,
        type: "",
        phones: "",
        regionTwo: "",
        familyID:null,
      },
      tiem: "",
      tiems: "",
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value: "",
      input: "",
      title: "",
      tableData: [],
      //分页
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1, //网络请求的页码
      unitNamePage: 0,
      keyIds: [],
      regionsId: null,
      regionsIds: null,
      //增加的表单
      formInline: {
        id: "",
        createDate: null,
        updateDate: null,
      },
    };
  },
  created() {
    this.getEquipment();
    api.getCitys({ parentId: 0 }).then((res) => {
      this.optionssex = res.data.result; //输出省
    });
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    nowState(index, row) {
      console.log("898989:", index, row);
      this.dialogVisibleFam = true;
      apiShout.getfamilyBy({ userId: row.id }).then((res) => {
        console.log("opopp:", res.data);
        if (res.data.code === 200) {
          this.familyArry = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    useStates(val) {
      console.log(val, "qiyong");
      this.useStateOne = val;
    },
    //市
    provinces(val) {
      this.ids = val;
      api.getCitys({ parentId: this.ids }).then((res) => {
        this.optionssexs = res.data.result; //渲染省
      });
    },
    //导出
    exportList() {
      // const listData = {
      //   startTime: this.overallForm.time[0],
      //   endTime: this.overallForm.time[1],
      //   imei: this.SerachInput.title,
      //   type: this.SerachInput.region,
      //   name: this.SerachInput.regions,
      // };
      // apiShout.exportEquipments(listData).then((res) => {
      //   const blob = new Blob([res.data], {
      //     type: "application/vnd.ms-excel",
      //     crossOrigin: "Anonymous",
      //   });
      //   const objectUrl = URL.createObjectURL(blob);
      //   window.location.href = objectUrl;
      // });
    },
    //查看图片
    open(row) {
      this.centerDialogVisible = true;
      this.photoUrls = row.photoUrl;
    },
    handleQuery() {
      this.getEquipment();
    },
    getEquipment() {
      apiShout
        .getAppUsers({
          pageCount: this.pageCount,
          pageSize: this.pageSize,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          userName: this.SerachInput.regionTwo,
          phone: this.SerachInput.phones,
          province: this.SerachInput.city,
          city: this.SerachInput.province,
          familyId:this.SerachInput.familyID,
        })
        .then((res) => {
          this.tableData = res.data.result.data;
          this.totalCount = res.data.result.page.totalCount;
        });
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      this.getEquipment();
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
      this.formInline = {};
    },
    //更改
    handleEdit(index, row) {
      this.formInline.id = row.id;
      this.ByIdsAll = row.id;
      if (this.ByIdsAll != null) {
        this.dialogVisible = true;
        api.smallLei({ dictCode: "usestate" }).then((res) => {
          this.optionStatus = res.data.result;
        });
      } else {
        this.dialogVisible = false;
      }
    },
    //保存
    preservation() {
      apiShout
        .updateStates({ id: this.ByIdsAll, useState: this.useStateOne })
        .then((res) => {
          if (res.data.code === 200) {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: "保存成功!",
            });
          } else {
            this.$message.error("保存失败！");
          }
          this.getEquipment();
        });
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-date-range-picker {
  width: 646px;
}
/deep/.el-date-range-picker .el-picker-panel__body {
  min-width: 513px;
}
.el-date-range-picker {
  width: 100%;
}
/deep/.el-form-item__content {
  margin-right: 48px;
  display: table;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
  padding: 0 10px;
}
/deep/.el-form-item {
  display: inline-table;
}
/deep/.el-col-1 {
  width: 5.17%;
}
/deep/.el-form-item__label {
  padding: 0px !important;
  text-align: left !important;
  color: #909399;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
}
.top {
  height: 200px;
  margin-left: 25px;
  margin-top: 2%;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}

.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
.table-content {
  padding: 30px 0 0 0;
  margin-right: 40px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
  margin-right: 30px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    margin-right: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  font-size: 15px;
  color: #606266;
  margin-top: 20px;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>